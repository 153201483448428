<template>
  <el-card shadow="always" v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>{{ $route.name }}</h4>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="medium"
          @click="openFirmAccountModal(null)"
        ></el-button>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Accounts"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="account_label" label="nome"></el-table-column>
      <el-table-column prop="bank.name" label="banco"></el-table-column>

      <el-table-column prop="agency" label="agência"></el-table-column>
      <el-table-column prop="account" label="conta"></el-table-column>
      <el-table-column label="ações">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                title="Editar conta"
                circle
                @click="openFirmAccountModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteFirmAccount(cat.row)"
                title="
              Remover carteira?
            "
                ><template #reference>
                  <el-button
                    onlyIcon
                    type="danger"
                    title="Apagar carteira"
                    circle
                  >
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <firm-bank-modal
      :showModal="showFirmBankModal"
      :account="account"
      @should-update="() => toggleIsLoading() | fetchAccounts()"
      @close-modal="() => (showFirmBankModal = false)"
    ></firm-bank-modal>
  </el-card>
</template>
<script>
import AccountService from "../services/accounts";
import FirmBankModal from "./modals/FirmBankModal.vue";
import { notifySuccess, notifyError } from "../utils/notifiers";
export default {
  name: "FirmBankAccounts",
  data() {
    return {
      accounts: null,
      showFirmBankModal: false,
      account: null,
      isLoading: true,
    };
  },
  components: { FirmBankModal },
  computed: {
    Accounts() {
      return this.accounts || [];
    },
  },
  mounted() {
    this.fetchAccounts();
  },
  methods: {
    toggleIsLoading() {
      this.isLoading = !this.isLoading;
    },
    openFirmAccountModal(account) {
      this.account = account;
      this.showFirmBankModal = true;
    },
    async deleteFirmAccount(acc) {
      const { account, error } = await AccountService(acc.uid).delete();

      if (account) notifySuccess(account.message);
      else notifyError(error.message);
    },
    async fetchAccounts() {
      const { accounts } = await AccountService().index();

      if (accounts) this.accounts = accounts;

      this.toggleIsLoading();
    },
  },
};
</script>
<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow" width="90%">
    <form>
      <el-row type="flex">
        <el-col> </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Pode emitir boletos:</h4>
        <el-switch
          v-model="account_.can_emit_billets"
          active-text="Sim"
          inactive-text="Não"
        >
        </el-switch>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Empresa:</h4>
      </el-row>
      <el-select
        remote
        filterable
        clearable
        :loading="!firms"
        :remote-method="fetchFirms"
        value-key="uid"
        v-model="account_.firm_id"
        size="large"
      >
        <el-option
          v-for="item in Firms"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Identificação da Conta:</h4>
      </el-row>
      <el-select
        v-model="account_.bank_id"
        value-key="id"
        size="medium"
        :loading="!banks"
        loading-text="Carregando bancos..."
        filterable
        remote
        :filter-method="fetchBanks"
      >
        <template #prefix>Banco:</template>
        <el-option
          v-for="item in Banks"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <base-input
        label="Nome:"
        hint="Adicione um nome para identificar essa carteira"
        v-model="account_.account_label"
      >
      </base-input>

      <el-row :gutter="4" type="flex">
        <el-col :md="16" :xs="24"
          ><base-input
            label="Nº:"
            hint="Número da conta sem dígito"
            mask="#########"
            v-model="account_.account"
          >
          </base-input
        ></el-col>

        <el-col :md="4" :xs="24"
          ><base-input
            label="DV:"
            hint=""
            mask="#########"
            v-model="account_.account_digit"
          >
          </base-input
        ></el-col>
      </el-row>
      <el-row :gutter="4" type="flex">
        <el-col :md="16" :xs="24"
          ><base-input
            label="Agência:"
            hint="Número da agência sem dígito"
            mask="#########"
            v-model="account_.agency"
          >
          </base-input
        ></el-col>

        <el-col :md="8" :xs="24"
          ><base-input
            label="DV:"
            hint=""
            mask="#########"
            v-model="account_.agency_digit"
          >
          </base-input
        ></el-col>
      </el-row>
      <base-input
        label="Código identificação da carteira"
        hint="Este é o código do provedor de pagamentos"
        v-model="account_.account_id"
      >
      </base-input>

      <el-select v-model="account_.account_type" clearable size="medium">
        <template #prefix> Tipo da conta: </template>
        <el-option
          v-for="t in AccountTypes"
          :key="t"
          :value="t"
          :label="t"
        ></el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Identificação da Carteira:</h4>
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Atualizar impressão após Vencimento?:</h4>
        <el-switch
          v-model="account_.should_update"
          active-text="Sim"
          inactive-text="Não"
        >
        </el-switch>
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Atualizar impressão nas Alterações?:</h4>
        <el-switch
          v-model="account_.should_update_alts"
          active-text="Sim"
          inactive-text="Não"
        >
        </el-switch>
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Boleto híbrido(PIX por QRCode):</h4>
        <el-switch
          v-model="account_.is_hybrid"
          active-text="Sim"
          inactive-text="Não"
          :disabled="account_.bank.code !== '001'"
        >
        </el-switch>
      </el-row>
      <base-input
        label="Numero da carteira"
        v-model="account_.agreement_number"
      >
      </base-input>
      <base-input
        label="Variação da carteira"
        v-model="account_.agreement_variation"
      >
      </base-input>
      <base-input label="Numero do convênio" v-model="account_.agreement_id">
      </base-input>
      <base-input
        label="Formato do Convenio"
        v-model="account_.agreement_format"
      >
      </base-input>
      <base-input label="Numero do Contrato" v-model="account_.document_id">
      </base-input>
      <base-input
        label="Multa atraso"
        type="money"
        v-model="account_.assessment_amount"
      >
      </base-input>
      <base-input label="Numero da Sequencia" v-model="account_.sequential">
      </base-input>
      <base-input
        label="Juro Diário"
        type="money"
        v-model="account_.assessment_daily"
      >
      </base-input>

      <el-row type="flex" justify="start">
        <h4>Mensagens:</h4>
      </el-row>
      <base-input label="Instrução de Pagamento 1" v-model="account_.msg_1">
      </base-input>
      <base-input label="Instrução de Pagamento 2" v-model="account_.msg_2">
      </base-input>
      <base-input label="Instrução de Pagamento 3" v-model="account_.msg_3">
      </base-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import BankService from "../../services/banks";
import AccountService from "../../services/accounts";
import FirmService from "../../services/firms";
import { notifySuccess, notifyError } from "../../utils/notifiers";
export default {
  props: ["account", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      account_: { ...this.account },
      banks: null,
      firms: null,
      isLoadingSave: false,
      systemActions: [],
    };
  },
  watch: {
    account(v) {
      this.account_ = { ...v };
      if (v?.firm) this.account_.firm_id = v?.firm?.uid;

      if (v?.bank) this.fetchBank(v?.bank?.id);
    },
  },
  computed: {
    ModalTitle() {
      return this.account
        ? "Editar carteira bancária"
        : "Cadastrar carteira bancária";
    },
    AccountTypes() {
      return ["Conta Corrente", "Poupança"];
    },
    Firms() {
      return this.firms || [];
    },
    Banks() {
      return this.banks || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  mounted() {
    this.fetchFirms();
  },
  methods: {
    async save() {
      this.isLoadingSave = true;
      this.account ? await this.updateAccount() : await this.createAccount();

      this.$emit("close-modal");
      this.$emit("should-update");
      this.isLoadingSave = false;
    },
    async fetchBanks(queryName) {
      this.banks = null;
      const { banks } = await BankService().index({ queryName });

      if (banks) this.banks = banks;
    },
    async fetchBank(bankId) {
      const { bank } = await BankService(bankId).get();

      if (bank) this.banks = [bank];
    },
    async fetchFirms() {
      const { firms } = await FirmService().index();

      if (firms) this.firms = firms;
    },
    async updateAccount() {
      const { account, error } = await AccountService(this.account.uid).update(
        this.account_
      );

      if (account) notifySuccess(account.message);
      else notifyError(error.message);
    },
    async createAccount() {
      const { account, error } = await AccountService().create(this.account_);

      if (account) {
        notifySuccess(account.message);
        this.account_ = {};
      } else notifyError(error.message);
    },
  },
  name: "FirmBankModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>
